import React from 'react'
import Layout from 'layouts/en'
import { ServiceState } from './index.tw'
import { Languages } from 'i18n/config'

import 'styles/base.less'

import i18n from 'i18n/en/i18n_service.json'

const Service: React.FC = (props) => {
  const demoUrl = '/form/parade/'
  const paradeUrl = ''

  return (
    <Layout {...props}>
      <ServiceState demoUrl={demoUrl} paradeUrl={paradeUrl} i18n={i18n} />
    </Layout>
  )
}

export default Service
